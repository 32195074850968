import Widgets from 'js-widget-hooks';
import $ from 'jquery';
import {debounce} from "underscore";

const MIN_SEARCH_CHARACTER_LENGTH = 3;
const DEBOUNCE_TIME_MS = 200;

const runAjax = function (query, callback) {
    $.get('/api/geo-suggestions.php?query=' + encodeURIComponent(query), function (res) {
        callback(res);
    })
};

const lazyAjax = debounce(runAjax, DEBOUNCE_TIME_MS);

Widgets.register('search-suggestion-input', function (el) {

    let currentId = 0;
    let currentVal = '';
    let currentSuggestions = [];

    const elem = $(el);
    const input = elem.find('.trigger-search');
    const form = elem.closest('form');
    const placeIdInput = form.find('#placeId');

    form.on('submit', function (e) {

        if (event.submitter.name === 'uebernehmen0') {

            input.removeClass('error');

            const values = Object.fromEntries(new FormData(form[0]));

            if (!values.placeId) {

                if (currentSuggestions.length) {
                    const theId = currentId < currentSuggestions.length ? currentId : 0;
                    placeIdInput.val(currentSuggestions[theId].id);
                    input.val(currentSuggestions[theId].label);
                } else {
                    e.preventDefault();
                    input.addClass('error');
                }
            }
        }
    });

    const markSuggestion = function (index) {
        $(elem.find('.search-suggestions li').removeClass('focus')[index]).addClass('focus');
    };

    input.on('keydown', function (e) {
        const keyCode = e.originalEvent.keyCode;

        switch (keyCode) {
            case 13:
                placeIdInput.val(currentSuggestions[currentId].id);
                input.val(currentSuggestions[currentId].label);
                break;
            case 38: // arrow up
                e.preventDefault();
                e.stopPropagation();
                currentId = currentId - 1 < 0 ? (currentSuggestions.length ? currentSuggestions.length - 1 : 0) : currentId - 1;
                markSuggestion(currentId);
                break;
            case 40: // arrow down
                e.preventDefault();
                e.stopPropagation();
                currentId = currentId + 1 >= currentSuggestions.length ? 0 : currentId + 1;
                markSuggestion(currentId);
                break;
        }

    });

    input.on('keyup click', function (e) {

        const keyCode = e.originalEvent.keyCode;

        if (keyCode === 38 || keyCode === 40) {
            e.preventDefault();
            e.stopPropagation();
        }

        const newVal = $(this).val();

        if (newVal !== currentVal) {
            currentVal = newVal;

            if (newVal.length >= MIN_SEARCH_CHARACTER_LENGTH) {

                input.addClass('loading');
                lazyAjax($(this).val(), function (suggestions) {
                    input.removeClass('loading');

                    currentSuggestions = suggestions;
                    currentId = 0;
                    placeIdInput.val('');

                    elem.find('.search-suggestions').remove();
                    elem.append('<ul class="search-suggestions">' + suggestions.map(function (s) {
                        return `<li data-id="${s.id}"><span>${s.label}</span></li>`
                    }).join('') + '</ul>');

                    markSuggestion(currentId);

                    elem.find('.search-suggestions li').on('click', function () {
                        placeIdInput.val($(this).data('id'));
                        input.val($(this).text());
                        form.submit();
                    });
                });
            } else {
                elem.find('.search-suggestions').remove();
                currentSuggestions = [];
                currentId = 0;
                placeIdInput.val('');
            }
        }
    })
});