import Widgets from 'js-widget-hooks';
import $ from 'jquery';
import './components/widgets';
import CookieBanner from "./components/cookie-banner";

// google analytics code
var gaProperty = 'UA-56469981-1';
var disableStr = 'ga-disable-' + gaProperty;
if (document.cookie.indexOf(disableStr + '=true') > -1) {
    window[disableStr] = true;
}

function gaOptout() {
    document.cookie = disableStr + '=true; expires=Thu, 31 Dec ' + ((new Date()).getFullYear() + 108) + ' 23:59:59 UTC; path=/';
    window[disableStr] = true;
    alert('Das Tracking durch Google Analytics wurde in Ihrem Browser für diese Website deaktiviert.');
}

$(document).ready(function() {

    CookieBanner.getConsent().then(() => {
        console.log('Cookies Approved');

        (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
            (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
            m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
        })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');
        ga('create', gaProperty, 'auto');
        ga('set', 'anonymizeIp', true);
        ga('send', 'pageview');

    }).catch(() => {
        console.log('Cookies rejected');
    })


    Widgets.init(null, {
        'widgetClass': 'js-widget'
    });

    // Tooltip only Text
    $('.masterTooltip').hover(function(){
        // Hover over code
        var title = $(this).attr('title');
        $(this).data('tipText', title).removeAttr('title');
        $('<p class="tooltip"></p>')
            .text(title)
            .appendTo('body')
            .fadeIn('slow');
    }, function() {
        // Hover out code
        $(this).attr('title', $(this).data('tipText'));
        $('.tooltip').remove();
    }).mousemove(function(e) {
        var mousex = e.pageX + 20; //Get X coordinates
        var mousey = e.pageY + 10; //Get Y coordinates
        $('.tooltip')
            .css({ top: mousey, left: mousex })
    });

    // to prevent copy paste errors
    $('.widget_email').on('change keyup', function () {
        var newVal = $.trim($(this).val());
        $(this).val(newVal);
    });


    $('.widget_validate').validate({
        errorClass: "my-error-class",
        validClass: "my-valid-class",
        rules: {
            email: {
                required: true,
                email: true
            }
        },
        messages: {
            email: {
                required: "<br/>Bitte Ihren Benutzernamen eingeben!",
                email: "<br/>Keine gültige E-Mailadresse!"
            }
        }
    });

    $('#tBauchraum, #tBrustraum, #tMaster, #tSonderausbildungen').on("click", function () {
            var n = $(this).parent().nextAll().has(":checkbox").first().find(":checkbox");

            if ($(n).prop('checked') == true) {
                $(n).prop('checked', false);
            } else {
                $(n).prop('checked', true);
            }
        }
    );

});

window.noBack = function() {
    window.history.forward();
}