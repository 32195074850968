import $ from 'jquery';
import 'jquery-validation';
import Widgets from 'js-widget-hooks';

$.validator.methods.email = function (value, element, param) {
    const regex = /^([\w\-\.]+@([\wäüöß\-]+\.)+[\w\-]{2,4})?$/gi;
    return regex.exec(value);
}

Widgets.register('validate', function (el) {
    const form = $(el);


    form.submit(function () {
        if ($(this).valid()) {
            $('input[type=submit]', this).attr('disabled', 'disabled');
        }
    });

    form.validate({
        errorClass: "my-error-class",
        validClass: "my-valid-class",
        rules: {

            tp_vorname: {
                required: true
            },

            tp_nachname: {
                required: true
            },

            strasse: {
                required: true
            },

            plz: {
                required: true,
                number: true,
                minlength: 4,
                maxlength: 6
            },

            ort: {
                required: true
            },

            land: {
                required: true
            },


            passwort: {

                minlength: 6,
                maxlength: 10
            },

            passwort2: {

                equalTo: "#passwort",
                minlength: 6,
                maxlength: 10
            },

            web: {
                url: true,
            },

            email: {
                required: true,
                email: true
            }
        },
        messages: {

            tp_vorname: {
                required: "<br/>Bitte Vorname angeben!",
            },

            tp_nachname: {
                required: "<br/>Bitte Nachname angeben!",
            },

            strasse: {
                required: "<br/>Bitte Strasse angeben!",
            },

            plz: {
                required: "<br/>Bitte Postleitzahl angeben!",
                number: "<br/>Bitte eine Zahl eingeben!",
                minlength: jQuery.validator.format("<br/>mindestens {4} Zeichen eingeben"),
                maxlength: jQuery.validator.format("<br/>maximal {6} Zeichen eingeben!"),
            },

            ort: {
                required: "<br/>Bitte Ort angeben!",
            },

            land: {
                required: "<br/>Bitte Land angeben!",
            },

            passwort: {
                required: "<br/>Bitte Passwort angeben!",
                minlength: jQuery.validator.format("<br/>mindestens {0} Zeichen eingeben"),
                maxlength: jQuery.validator.format("<br/>maximal {0} Zeichen eingeben!"),
            },

            passwort2: {
                required: "<br/>Bitte Passwort wiederholen!",
                equalTo: "<br/>Passwort wiederholen",
                minlength: jQuery.validator.format("<br/>mindestens {0} Zeichen eingeben"),
                maxlength: jQuery.validator.format("<br/>maximal {0} Zeichen eingeben!"),

            },

            email: {
                required: "<br/>Bitte E-Mail-Adresse eingeben!",
                email: "<br/>E-Mail im Format name@domain.de eingeben!"
            },

            web: {
                url: "<br/>Bitte eine gültige Webadresse eingeben! (http://www.)",
            }
        }
    });

})