import $ from 'jquery';
import Widgets from 'js-widget-hooks';

Widgets.register('map', function (el) {

    el.classList.add('loading');

    const elem = $(el);
    const map = $('<div class="map"></div>');
    elem.append(map);


    const config = elem.data();

    import(/* webpackChunkName: "map" */ '../map/map-handler').then(function (module) {
        module.default(map, config);
        el.classList.remove('loading');
    });

});