import $ from 'jquery';
const consentKey = 'cookie-consent';

export default {

    getConsent : function() {

        return new Promise((resolve, reject) => {
            if (window.localStorage.getItem(consentKey)) {
                if (JSON.parse(window.localStorage.getItem(consentKey))) {
                    resolve()
                }

                reject();
            } else {
                const banner = $('<div role="alertdialog" aria-labelledby="popup-text" class="eu-cookie-compliance-banner eu-cookie-compliance-banner-info eu-cookie-compliance-banner--opt-in">' +
                    '<div class="popup-content info eu-cookie-compliance-content">' +
                        '<div id="popup-text" class="eu-cookie-compliance-message">' +
                            '<h2>Wir verwenden auf dieser Website Cookies, um die Gebrauchsfreundlichkeit zu verbessern</h2>' +
                            '<p>Klicken Sie "Akzeptieren", um sich damit einverstanden zu erklären, die Cookies PHPSESSID und __session sind technisch notwendig und können nicht abgelehnt werden. ' +
                            '<a href="/datenschutzerklaerung.php#cookies" class="eu-cookie-compliance-link">Weitere Informationen</a>' +
                        '</p>' +
                        '</div>' +
                            '<div id="popup-buttons" class="eu-cookie-compliance-buttons">' +
                                '<button type="button" class="agree-button button eu-cookie-compliance-button eu-cookie-compliance-secondary-button">Akzeptieren</button> ' +
                                '<button type="button" class="decline-button button eu-cookie-compliance-button eu-cookie-compliance-default-button">Nein, danke</button>' +
                            '</div>' +
                        '</div>' +
                    '</div>');

                banner.find('.agree-button').click(function () {
                    window.localStorage.setItem(consentKey, JSON.stringify(true));
                    banner.hide();
                    resolve();
                })

                banner.find('.decline-button').click(function () {
                    window.localStorage.setItem(consentKey, JSON.stringify(false));
                    banner.hide();
                    reject();
                })

                $('body').append(banner);

            }
        });
    }
}